import React from "react"
import TemplatePageGuide from "@components/pageWebinar/template"
import { Helmet } from "react-helmet"
import data from "@components/pageWebinar/webinar/webinar2/dataPage"

const IndexPage = ({ location }) => (
  <div className="fnd">
    <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        Cómo Huawei Cloud y Beex aceleran la escalabilidad a los Contact Center 
        [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/webinar/huawei/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre cómo Huawei Cloud y Beex trabajan juntos para impulsar soluciones que optimizan la gestión en los Contact Center."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Cómo Huawei Cloud y Beex aceleran la escalabilidad a los Contact Center "
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/webinar/huawei/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Descubre cómo Huawei Cloud y Beex trabajan juntos para impulsar soluciones que optimizan la gestión en los Contact Center."
      />
    </Helmet>
    <TemplatePageGuide
      location={location}
      landing="webinar2"
      data={data}
    />
  </div>
)

export default IndexPage
